import React, { useContext } from "react";
// Icons
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Warning from "@material-ui/icons/Warning";
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import { Replay, DashboardOutlined } from "@material-ui/icons";

// Card
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

// Core
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import useTickets from "../../hooks/useTickets";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import Chart from "./Chart";
import Chart2 from "./Chart2";
import Chart3 from "./Chart3";
//import Chart4 from "./Chart4"; // NPS
import { Hidden } from "@material-ui/core";

// Date
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Securiy
import { Can } from "../../components/Can";

const useStyles = makeStyles(theme => ({
	container: {
		flexGrow: 1,
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 320,
	},
	fixedHeightVendedor: {
		padding: theme.spacing(3),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 320,
	},

	fixedHeightNPS: {
		padding: theme.spacing(3),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 640,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	media: {
		height: 0,
	},
	avatar1: { backgroundColor: "#1c6434",},
	avatar2: { backgroundColor: "#FFF000",},
	avatar3: { backgroundColor: "#FF0000",},
	avatar4: { backgroundColor: "#0000FF",},
	avatar5: {backgroundColor: "#C1C1C1",},
	aba: {
		flexGrow: 1,
		paddingBottom: 10,
	},
	dateFilter: {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
	paper: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
		backgroundColor: "#fafafa",
		borderRadius: 5,
	  },
}));

const Dashboard = () => {

	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	/*const datenow = new Date();
	let dateStart =  (new Date(datenow.getFullYear(), datenow.getMonth(), 1));//Inicio do Mes
	let dateEnd =  (new Date(datenow.getFullYear(), datenow.getMonth() + 1, 0));// Fim do Mes
	*/
	const classes = useStyles()

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return count;
	}

	const GetTicketsTotal = (showAll, withUnreadMessages) => {

		const { count } = useTickets({
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		return count;
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={1}>
					<Hidden only={["xs", "sm"]}>
					<Can
						role={user.profile}
						perform="dashboard-chart:view"
						yes={() => (
							<>
								<Grid item xs={12}>
									<Card>
										<CardHeader
											avatar={
											<Avatar aria-label="recipe" className={classes.avatar5}>
												<DashboardOutlined />
											</Avatar>
											}
											action={
												<Typography component="h1" variant="h3" color="primary" paragraph>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<Grid container justifyContent="space-around">
														<KeyboardDatePicker
															className={classes.dateFilter}
															disableToolbar
															variant="inline"
															format="dd/MM/yyyy"
															margin="normal"
															id="date-picker-inline"
															label="Data inicial"
															value={selectedDate}
															onChange={handleDateChange}
															KeyboardButtonProps={{
																'aria-label': 'change date',
															}}
														/>
														<KeyboardDatePicker
															className={classes.dateFilter}
															disableToolbar
															variant="inline"
															format="dd/MM/yyyy"
															margin="normal"
															id="date-picker-inline1"
															label="Date final"
															value={selectedDate}
															onChange={handleDateChange}
															KeyboardButtonProps={{
																'aria-label': 'change date',
															}}
														/>
														<Button
															size="small"
															variant="contained"
															color="primary"
															//onClick={enableCrateButton}
															className={classes.dateFilter}
														>
															<Replay />
														</Button>
													</Grid>
													</MuiPickersUtilsProvider>
												</Typography>
											}
											title={i18n.t("Dashboard")}
											subheader="dados estatisticos"
										/>
									</Card>						
								</Grid>

							</>
						)}
						/>
					<Can
						role={user.profile}
						perform="dashboard-chart:showview"
						yes={() => (
							<>
						<Grid item xs={3}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar1}>
										<WhatsAppIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("open", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.inAttendance.title")}
									subheader="conversas abertas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={3}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar2}>
										<Warning />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("pending", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.waiting.title")}
									subheader="conversas em espera"
								/>
							</Card>					
						</Grid>
						<Grid item xs={3}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar3}>
										<SpeakerNotesOffIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("closed", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.closed.title")}
									subheader="conversas finalizadas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={3}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar4}>
										<WhatsAppIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTicketsTotal("true", "false")}
										</Typography>
									}
									title="Total Geral"
									subheader="total de conversas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.fixedHeightPaper}>
								<Chart />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.fixedHeightVendedor}>
								<Chart2 />
							</Paper>
						</Grid>
					{/*	<Grid item xs={12}>
							<Paper className={classes.fixedHeightVendedor}>
								<Chart3 />
							</Paper>
						</Grid>	*/}	


							</>
						)}
						/>
		
					</Hidden>
					<Hidden only={["md","xl","lg"]}>
						<Grid item xs={12}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar1}>
										<WhatsAppIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("open", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.inAttendance.title")}
									subheader="conversas abertas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar2}>
										<Warning />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("pending", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.waiting.title")}
									subheader="conversas em espera"
								/>
							</Card>					
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar3}>
										<SpeakerNotesOffIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTickets("closed", "true", "false")}
										</Typography>
									}
									title={i18n.t("dashboard.messages.closed.title")}
									subheader="conversas finalizadas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardHeader
									avatar={
									<Avatar aria-label="recipe" className={classes.avatar4}>
										<WhatsAppIcon />
									</Avatar>
									}
									action={
										<Typography component="h1" variant="h3" color="primary" paragraph>
											{GetTicketsTotal()}
										</Typography>
									}
									title="Total Geral"
									subheader="total de conversas"
								/>
							</Card>					
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.fixedHeightPaper}>
								<Chart />
							</Paper>
						</Grid>
					</Hidden>
				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard;